import AssetCard from "./AssetCard";
import NoAssetCard from "./NoAssetCard";
import { EditOutlined, DeleteOutlined, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Space } from 'antd';
import { useState, useEffect} from "react";
import AddAssetModal from "./AddAssetModal";
import EditWorkstationModal from "./EditWorkstationModal";
import ChangeAssetPositionModal from "./ChangeAssetPositionModal";
import Scrollbars from "react-custom-scrollbars-2";
import { transformToSelectablesUtils } from "../../utils/utils";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";

const WorkstationDetails = ({ name, setSelection, auth, id, assets, callbackFct, setMenuItemKey, selectId, menuItemKey, setForceRerender, customer}) => {

    const [assetList, setAssetList] = useState ([]);
    const [allAssets, setAllAssets] = useState ([]);

    const [ addAssetModalIsOpen, setAssetModalIsOpen ] = useState(0);
    const [ editWorkstationModalIsOpen, setEditWorkstationModalIsOpen ] = useState(0);
    const [ changePositionModalIsOpen, setChangePositionModalIsOpen ] = useState(0);

    const [hasServiceManagerRole, setRole] = useState(false);

    //Check Auth
    useEffect(() => {
        if (auth.user !== undefined) {
            loadCustomers();
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
    }, [auth])

    const loadCustomers = () => {
        //Insert API call
    }

    const openAddAssetModal = () => {
        setAssetModalIsOpen(addAssetModalIsOpen + 1)
    };

    const openEditWorkstationModal = () => {
        setEditWorkstationModalIsOpen(editWorkstationModalIsOpen + 1)
    };

    const openPositionModal = () => {
        setChangePositionModalIsOpen(changePositionModalIsOpen + 1)
    }

    useEffect(() => {
        const tempAssetList = assets;
        const wsAssets = findWsAssets(tempAssetList, id);
        setAssetList(wsAssets);
        setAllAssets(assets);
    }, [id, assets])

    const findWsAssets = (data, targetId) => {
        for (const element of data) {
            if (element.id === targetId && element.assets && element.assets.length > 0) {
                return element.assets.sort((a, b) => a.position - b.position); // Sorted Assets
            }
        }
    }

    const { t } = useTranslation();

    //setMenuItemKey(selectId);
    function findSelectIdForAsset( currentId, selectables) {
        for (const key in selectables) {
            if (selectables.hasOwnProperty(key)) {
                const value = selectables[key];
                if (value.id === currentId) {
                    return key;
                }
            }
        }
        return null;

    }

    function AssetList({data, setSelection}) {
        
        if (data !== undefined && data.length > 0){
            return (
                <div 
                    style={{ display: "flex", flexWrap: 'wrap'}}>
                {data.map(asset => (
                    <AssetCard
                    selectId={findSelectIdForAsset(asset.id, transformToSelectablesUtils(allAssets))}
                    name={asset.name}
                    setSelection={setSelection}
                    workstationName={name}
                    internal_identifier={asset.internal_identifier}
                    id={asset.id}
                    position={asset.position}
                    type={asset.type}
                    type_id={asset.type_id}
                    manufacturer={asset.manufacturer}
                    equipment_number={asset.equipment_number}
                    ticketCount={asset.ticket_count}
                    key={asset.id}
                    />
                ))}
                </div>
            );
        } else return <NoAssetCard />
    }

    const buttonsDisabled = !id;

    return (
        <div>
            <Space 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px 8px 0 0",
                    padding: "0 10px 0 10px"}}>
                <h2>
                    {name} 
                    {hasServiceManagerRole && (
                        <>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.edit-ws-tooltip')}>
                                <Button 
                                    className="asset-btn-round" 
                                    ghost type="primary" 
                                    shape="circle" 
                                    onClick={() => openEditWorkstationModal()} 
                                    icon={<EditOutlined />} 
                                    disabled={buttonsDisabled}
                                />

                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.add-asset-tooltip')}>
                                <Button 
                                    className="asset-btn-round" 
                                    ghost 
                                    type="primary" 
                                    shape="circle" 
                                    onClick={() => openAddAssetModal()} 
                                    icon={<PlusOutlined />} 
                                    disabled={buttonsDisabled}
                                />
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.change-position-tooltip')}>
                                <Button 
                                    className="asset-btn-round" 
                                    ghost 
                                    type="primary" 
                                    shape="circle" 
                                    onClick={() => openPositionModal()} 
                                    icon={<SwapOutlined rotate={90} />} 
                                    disabled={buttonsDisabled}
                                />
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title={t('assets.overview.delete-ws-tooltip')}>
                                <Button 
                                    className="asset-btn-round" 
                                    ghost 
                                    danger 
                                    disabled 
                                    type="primary" 
                                    shape="circle" 
                                    icon={<DeleteOutlined />}
                                />
                            </Tooltip>
                    </>
                    )}
                        
                    
                </h2>
            </Space>         
            <div 
                style={{ 
                    display: "flex",
                    paddingBottom: "12px",
                    paddingTop: "8px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "0 8px 8px 8px"
                }}
            >
                <Scrollbars
                    autohide="true"
                    style={{minHeight: "60vh"}}
                >
                    <AssetList data={assetList} setSelection={setSelection} />
                </Scrollbars>
            </div>
            
            <AddAssetModal
                isOpen={addAssetModalIsOpen} auth={auth} callbackFct={callbackFct} workstation_id={id}
                setMenuItemKey={setMenuItemKey} selectId={selectId} workstation_name={name} customer={customer}
            />
            <EditWorkstationModal
                isOpen={editWorkstationModalIsOpen} auth={auth} callbackFct={callbackFct} id={id} name={name}
                setMenuItemKey={setMenuItemKey} selectId={selectId} customer={customer}
            />
            <ChangeAssetPositionModal
                isOpen={changePositionModalIsOpen} auth={auth} setSelection={setSelection} externalSetKey={id}
                assetList={assetList} menuItemKey={menuItemKey} workstation_id={id} setForceRerender={setForceRerender}
                customer={customer}
            />
        </div>
    )
}

export default WorkstationDetails;