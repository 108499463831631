import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useEffect, useState} from "react";
import { GlobalContextProvider } from "./utils/GlobalContext";
import { getAvatarColor } from "./utils/utils"
import UnreadNotificationList from "./components/TopMenu/UnreadNotificationList";
import './App.css'
import { Layout, Menu, Avatar, ConfigProvider } from 'antd';
import { LogoutOutlined } from "@ant-design/icons";
import {hasAuthParams, useAuth} from "react-oidc-context";
import {USER_ROLE_MANUFACTURER_ENGINEER, USER_ROLE_SERVICE_MANAGER, USER_ROLE_ADMIN, userHasRole} from "./utils/userManagement";
import './globalStyles.css';
import Footer from "./components/Generics/Footer";

const { Header, Content } = Layout;



function App({error}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const [userSignature, setUserSignature] = useState("")
    const [userHasRoleServiceManager, setUserHasRoleServiceManager] = useState(false)
    const [userHasRoleManufacturerEngineer, setUserHasRoleManufacturerEngineer] = useState(false)
    const [userHasRoleAdmin, setUserHasRoleAdmin] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [selectedKey, setSelectedKey] = useState(['']);
    const [accessValidated, setAccessValidated] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false)
    const [showWithoutLogin, setShowWithoutLogin] = useState(false)
    const location = useLocation();

    const accountMenuItems = [
        {
            label: <Avatar size= "large" style={{ backgroundColor: getAvatarColor(userSignature) }} >{userSignature}</Avatar>,
            children: [
                /* {
                    type: "group",
                    label: "Portal",
                    children: [
                        {
                            label: "Settings",
                            key: "settings",
                            icon: <SettingOutlined />
                        },
                        {
                            label: "Help",
                            key: "help",
                            icon: <QuestionCircleOutlined />
                        }
                    ]
                }, */
                {
                    type: "group",
                    label: "Account",
                    children: [
                        {
                            label: "Logout",
                            key: "logout",
                            icon: <LogoutOutlined />
                        }
                    ]
                }
            ]
            }
    ]


    const onAccountMenuClick = (event) => {
        if (event.key === "logout") {
            auth.removeUser()
            auth.signoutRedirect({
                id_token_hint: auth.user.id_token,
                post_logout_redirect_uri: window.location.href
            })
            setHasTriedSignin(false)
        }
    };

    async function mSpaceSync() {
        try {
            let response = await fetch(process.env.REACT_APP_MSPACE_MANAGER_URL + "/sync", {
                headers: {
                    "Authorization": 'Bearer ' + auth.user.access_token,
                },
                method: "GET"
            })

            return await response.json()

        } catch (error) {
            throw new Error("Error requesting API:", error);
        }
    }

    // Check if user has access to page and load menu items based on the user's roles
    useEffect(() => {
        if (auth.isAuthenticated) {
            // Check if user has access

            switch (location.pathname.split('/')[1]) {
                case 'administration':
                    if (!userHasRoleManufacturerEngineer) {
                        window.location.replace("/tickets");
                    }
                    break;
                case 'requests':
                    if (!userHasRoleManufacturerEngineer) {
                        window.location.replace("/tickets");
                    }
                    break;
                case 'assets':
                    if (!userHasRoleServiceManager) {
                        window.location.replace("/requests");
                    }
                    break;
                default:
                    break;
            }

            setAccessValidated(true)
        }

        let newMenuItems = []

        if (userHasRoleServiceManager) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/assets`}>{t('menu.assets')}</Link>,
                  key: 'assets',   
                },
                { label: <Link to={`/tickets`}>{t('menu.tickets')}</Link>,
                  key: 'tickets',
                },
            ]
        }
        if (userHasRoleManufacturerEngineer) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/requests`}>{t('menu.requests')}</Link>,
                  key: 'requests',
                },
            ]
        }
        if (userHasRoleAdmin) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/administration`}>{t('menu.admin')}</Link>,
                  key: 'administration', 
                },
             ]
        }
        setMenuItems(newMenuItems)

        // eslint-disable-next-line
    }, [userHasRoleServiceManager, userHasRoleManufacturerEngineer])

    // Silent login if a user re-visits the page and is still logged in
    useEffect(() => {
        if (!auth.isAuthenticated && window.location.pathname === "/terms-and-conditions") {
            setShowWithoutLogin(true)
            return
        }

        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect()
            setHasTriedSignin(true)
        } else if (auth.isAuthenticated) {
            if(sessionStorage.getItem("new-session") === "true" && auth.user.profile.pt_source === "mspace") {
                mSpaceSync().then(response => {
                    if (response.access_denied) {
                        setAccessDenied(true)
                    }
                    if(response.updated) {
                        // Refresh the token
                        auth.signinSilent()
                    }

                    sessionStorage.removeItem("new-session")

                    setUserSignature(auth.user.profile.name.charAt(0) + auth.user.profile.family_name.charAt(0))
                    setUserHasRoleServiceManager(userHasRole(auth.user.profile, USER_ROLE_SERVICE_MANAGER))
                    setUserHasRoleManufacturerEngineer(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
                    setUserHasRoleAdmin(userHasRole(auth.user.profile, USER_ROLE_ADMIN))
                })

            } else {
                setUserSignature(auth.user.profile.name.charAt(0) + auth.user.profile.family_name.charAt(0))

                const hasRoleServiceManager = userHasRole(auth.user.profile, USER_ROLE_SERVICE_MANAGER)
                const hasRoleManufacturerEngineer = userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER)
                const hasRoleAdmin = userHasRole(auth.user.profile, USER_ROLE_ADMIN)

                setUserHasRoleServiceManager(hasRoleServiceManager)
                setUserHasRoleManufacturerEngineer(hasRoleManufacturerEngineer)
                setUserHasRoleAdmin(hasRoleAdmin)

                // Catches the case that the user opens the page during the same session even when he has no access
                if (!hasRoleServiceManager && !hasRoleManufacturerEngineer) {
                    setAccessDenied(true)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth, hasTriedSignin])

    useEffect(() => {
        if (accessDenied) {
            if(window.location.pathname !== "/access_denied") {
                navigate("/access_denied");
            }
        }
        // eslint-disable-next-line
    }, [accessDenied])

    //Keep selected menu key after refreshing
    useEffect(() => {
        if (auth.isAuthenticated) {
            const pathname = location.pathname;
            const key = getMenuKeyByPathname(pathname);
            setSelectedKey(key);
        }
        // eslint-disable-next-line
    }, [location, auth, userHasRoleServiceManager]);

    const getMenuKeyByPathname = (pathname) => {
        switch (pathname.split('/')[1]) {
            case 'tickets':
                if (userHasRoleServiceManager) {
                    return ['tickets'];
                } else {
                    return ['requests'];
                }
            case 'administration':
                return ['administration'];
            case 'requests':
                return ['requests'];
            case 'assets':
                return ['assets'];
            default:
                return [''];
        }
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    borderRadius: 1,
                    colorPrimary: "#E87722",
                    colorLink: "#E87722",
                    colorLinkHover: "#0C2340",
                    colorTextBase: "#0C2340",
                    fontFamily: 'Gotham-Book, Arial, sans-serif'
                },
            }}
        >
            <div>
            { !auth.isLoading && auth.isAuthenticated && accessValidated && !accessDenied &&
                <GlobalContextProvider auth={auth}>
                    <Layout className="layout">
                        <Header className="pt-header" style={{padding: "0px 0px 0px 50px"}}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div style={{display: "flex", justifyContent: "start"}}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img src="/img/logos/PT_Logo_Header.svg" alt="Logo" style={{ height: "40px", marginRight: "20px" }} />
                                    </div>
                                    <div>
                                        <Menu
                                            theme="dark"
                                            className="pt-header"
                                            mode="horizontal"
                                            defaultSelectedKeys={['assets']}
                                            selectedKeys={selectedKey}
                                            items={menuItems}
                                            style={{minWidth: "800px"}}
                                        />
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <UnreadNotificationList />
                                    <Menu
                                        style={{marginLeft: 15}}
                                        theme="dark"
                                        className="pt-header"
                                        mode="horizontal"
                                        defaultSelectedKeys={['1']}
                                        items={accountMenuItems}
                                        onClick={onAccountMenuClick}
                                        //style={{marginBottom: "8px"}}
                                    />
                                </div>
                                {/* <Avatar size= "large" style={{ backgroundColor: getAvatarColor("MR"),marginTop: "12px" }} >MR</Avatar> */}
                            </div>
                        </Header>
                        <Content
                            style={{
                                padding: '0 50px',
                            }}
                        >
                            {error ? error : <Outlet />}
                        </Content>
                        <Footer />
                    </Layout>
                </GlobalContextProvider>
            }
            { !auth.isLoading && auth.isAuthenticated && accessDenied &&
                <Layout className="layout">
                    <Header style={{padding: "0px 0px 0px 50px"}}>
                        <div style={{display:"flex", justifyContent: "space-between"}}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <img src="/img/logos/PT_Logo_Header.svg" alt="Logo" style={{height: "40px", marginRight: "20px", marginTop: "10px"}}/>
                                </div>
                                <Menu
                                    theme="dark"
                                    mode="horizontal"
                                    style={{minWidth: "800px"}}
                                />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Menu
                                    style={{marginLeft: 15}}
                                    theme="dark"
                                    mode="horizontal"
                                    defaultSelectedKeys={['1']}
                                    items={accountMenuItems}
                                    onClick={onAccountMenuClick}
                                />
                            </div>
                        </div>
                    </Header>
                    <Content
                        style={{
                            padding: '0 50px',
                            paddingBottom: "80px",
                            marginBottom: "64px"
                        }}
                    >
                        {error ? error : <Outlet />}
                    </Content>
                    <Footer />
                </Layout>
            }
            { showWithoutLogin &&
                <Layout className="layout">
                    <Header className="pt-header" style={{padding: "0px 0px 0px 50px"}}>
                        <div style={{display:"flex", justifyContent: "space-between"}}>
                            <div style={{display: "flex", justifyContent: "start"}}>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <img src="/img/logos/PT_Logo_Header.svg" alt="Logo" style={{ height: "40px", marginRight: "20px", marginTop: "10px" }} />
                                </div>
                                <div>
                                    <Menu
                                        theme="dark"
                                        className="pt-header"
                                        mode="horizontal"
                                        defaultSelectedKeys={['assets']}
                                        selectedKeys={[]}
                                        items={[]}
                                        style={{minWidth: "800px"}}
                                    />
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Menu
                                    style={{marginLeft: 15}}
                                    theme="dark"
                                    className="pt-header"
                                    mode="horizontal"
                                    defaultSelectedKeys={['1']}
                                />
                            </div>
                        </div>
                    </Header>
                    <Content
                        style={{
                            padding: '0 50px',
                            paddingBottom: "80px",
                            marginBottom: "64px"
                        }}
                    >
                        {error ? error : <Outlet />}
                    </Content>
                    <Footer />
                </Layout>
            }
        </div>
    </ConfigProvider>
    );
}

export default App;
