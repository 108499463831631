import { Button, Modal } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddNotificationModal from "./AddNotificationModal";
import EditNotificationModal from "./EditNotificationModal";
import { fetchApi, getApi } from "../../../utils/RequestBuilder";
import NotificationsTable from "./NotificationsTable";

function NotificationsOverview({auth}) {
    const { t } = useTranslation();

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const [userList, setUserList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [assetTypeList, setAssetTypeList] = useState([]);
    // List of asset types not grouped by product line
    const [plainAssetTypeList, setPlainAssetTypeList] = useState([]);
    const [moduleList, setModuleList] = useState([]);

    const [criteriaId, setSetCriteriaId] = useState(0)
    const [tableReload, setTableReload] = useState(0)

    function editCriteria(id) {
        setSetCriteriaId(id)
        setIsEditModalOpen(true)
    }

    async function loadDataFromTicketService(path, setCallback) {
        try {
            const jsonData = await fetchApi(auth, path)
            setCallback(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    async function loadDataFromManuManagement(path, setCallback) {
        try {
            const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + path)
            setCallback(jsonData);
        } catch (error) {
            // Handle error
        }
    }

   

    useEffect(() => {
        loadDataFromTicketService("/manufacturer-engineers", setUserList)
        loadDataFromTicketService("/customers", setCustomerList)
        loadDataFromManuManagement("/manufacturers/1/asset-types", setAssetTypeList)
        loadDataFromManuManagement("/asset-types", setPlainAssetTypeList)
        loadDataFromManuManagement("/modules", setModuleList)
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Button
                type="primary"
                onClick={() => setIsAddModalOpen(true)}
                style={{marginTop: "5px", marginBottom: "15px", marginRight: "15px"}}
            >
                <PlusOutlined />{t('administration.notifications.form.add')}
            </Button>
        <InfoCircleOutlined
                onClick={() => setIsInfoModalOpen(true)}
                style={{marginTop: "4px", marginBottom: "12px", marginLeft: "12px"}}
            />
            <NotificationsTable
                assetTypesPlain={plainAssetTypeList}
                auth={auth}
                customers={customerList}
                editCallback={editCriteria}
                modules={moduleList}
                users={userList}
                reload={tableReload}
            />
            <Modal
                title={t('administration.mail-notifications.info-modal.title')}
                open={isInfoModalOpen}
                onCancel={() => setIsInfoModalOpen(false)}
                onOk={() => setIsInfoModalOpen(false)}
                footer={[
                <Button key="submit" type="primary" onClick={() => setIsInfoModalOpen(false)}>
                        OK
                    </Button>,
                ]}
            >
                <p>
                    {t('administration.mail-notifications.info-modal.p1')}<br/>
                    {t('administration.mail-notifications.info-modal.p2')}
                </p>
            </Modal>
            <AddNotificationModal
                auth={auth}
                assetTypesList={assetTypeList}
                modalIsOpenCallback={setIsAddModalOpen}
                customerList={customerList}
                isOpen={isAddModalOpen}
                userList={userList}
                reloadCallback={setTableReload}
                reload={tableReload}
            />
            <EditNotificationModal
                auth={auth}
                assetTypesList={assetTypeList}
                modalIsOpenCallback={setIsEditModalOpen}
                customerList={customerList}
                criteriaId={criteriaId}
                isOpen={isEditModalOpen}
                userList={userList}
                reloadCallback={setTableReload}
                reload={tableReload}
            />
        </div>
    )
}

export default NotificationsOverview