import { useTranslation } from 'react-i18next';
import { Tabs, notification} from 'antd';
import React, { useState, useEffect } from 'react';
import AssetTypesOverview from "../../components/Administration/AssetTypes/AssetTypesOverview";
import AssetsManufacturer from "../Assets/AssetsManufacturer";
import ModulesOverview from "../../components/Administration/Modules/ModulesOverview";
import IssuesOverview from "../../components/Administration/Issues/IssuesOverview";
import NotificationsOverview from "../../components/Administration/Notifications/NotificationsOverview";
import { useAuth } from 'react-oidc-context';

function Administration() {

    const tabAssetType = "asset_types"
    const tabModule = "module"
    const tabIssue = "issues"
    const tabNotification = "notifications"
    const tabAssetManagement = "asset-management"

    const auth = useAuth();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [activeTab, setActiveTab] = useState(tabAssetType);
    const [reloadAssetTypes, setReloadAssetTypes] = useState(false); 

    useEffect(() => {
        if (window.location.hash !== "") {
            let tab = window.location.hash.replace("#", "")

            let validTabs = [tabAssetType, tabModule, tabIssue, tabNotification, tabAssetManagement]

            if (validTabs.includes(tab)) {
                setActiveTab(tab)
            } else {
                window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeTab
            }
        }
        // eslint-disable-next-line
    }, [])

    function onChangeTab(activeKey) {
        setActiveTab(activeKey);
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeKey;
        if (activeKey === tabAssetType) {
            setReloadAssetTypes(true);
            setTimeout(() => setReloadAssetTypes(false), 0); 
        }
    }

    const tabItems = [
        {
            key: tabAssetType,
            label: t('administration.general.tabs.asset-types'),
            children: (
                <div style={{ marginTop: "10px" }}>
                    {!reloadAssetTypes && <AssetTypesOverview />}
                </div>
            ),
        },
        {
            key: tabModule,
            label: t('administration.general.tabs.modules'),
            children: (
                <div style={{ marginTop: "10px" }}>
                    <ModulesOverview />
                </div>
            ),
        },
        {
            key: tabIssue,
            label: t('administration.general.tabs.issues'),
            children: (
                <div style={{ marginTop: "10px" }}>
                    <IssuesOverview />
                </div>
            ),
        },
        {
            key: tabNotification,
            label: t('administration.general.tabs.notifications'),
            children: (
                <div style={{ marginTop: "10px" }}>
                    <NotificationsOverview auth={auth} />
                </div>
            ),
        },
        {
            key: tabAssetManagement,
            label: t('administration.general.tabs.asset-management'),
            children: (
                <div style={{ marginTop: "10px" }}>
                    <AssetsManufacturer />
                </div>
            ),
        },
    ];



    return (
        <>
            {contextHolder}
            <div>
                <h2>{t('administration.general.title')}</h2>
                <Tabs
                    type='card'
                    size='large'
                    onChange={onChangeTab}
                    activeKey={activeTab}
                    items={tabItems}
                    
                />

            </div>
        </>
    );
}

export default Administration;
